export const menuItens = [
  {
    id: 'institucional',
    label: 'Institucional',
    reference: 'institucional',
    url: 'sebrae-unidade/institucional',
    icon: 'buildings/building-2-fill',
  },
  {
    id: 'atos_normativos',
    label: 'Atos Normativos',
    reference: 'atos_normativos',
    url: 'sebrae-unidade/atos_normativos',
    icon: 'finance/auction-fill',
  },
  {
    id: 'programas_projetos_resultados',
    label: 'Estratégia e Resultados',
    reference: 'programas_projetos_resultados',
    url: 'sebrae-unidade/programas_projetos_resultados',
    icon: 'business/bar-chart-box-fill',
  },
  {
    id: 'orçamento_execucao_orcamentaria',
    label: 'Orçamento e Execução Orçamentária',
    reference: 'orçamento_execucao_orcamentaria',
    url: 'sebrae-unidade/orçamento_execucao_orcamentaria',
    icon: 'business/calculator-fill',
  },
  {
    id: 'licitacoes',
    label: 'Licitações',
    reference: 'licitacoes',
    url: 'sebrae-unidade/licitacoes',
    icon: 'business/award-fill',
  },
  {
    id: 'credenciamento',
    label: 'Credenciamento',
    reference: 'credenciamento',
    url: 'sebrae-unidade/credenciamento',
    icon: 'document/draft-fill',
  },
  {
    id: 'contratos_convenios',
    label: 'Contratos',
    reference: 'contratos_convenios',
    url: 'sebrae-unidade/contratos_convenios',
    icon: 'development/git-repository-fill',
  },
  {
    id: 'convenios_transferencia',
    label: 'Convênios e Transferências',
    reference: 'convenios_transferencia',
    url: 'sebrae-unidade/convenios_transferencia',
    icon: 'finance/exchange-dollar-fill',
  },
  {
    id: 'demonstracoes_contabeis',
    label: 'Demonstrações Contábeis',
    reference: 'demonstracoes_contabeis',
    url: 'sebrae-unidade/demonstracoes_contabeis',
    icon: 'finance/coins-fill',
  },
  {
    id: 'dirigentes_empregados',
    label: 'Dirigentes e Empregados',
    reference: 'dirigentes_empregados',
    url: 'sebrae-unidade/dirigentes_empregados',
    icon: 'user/team-fill',
  },
  {
    id: 'prestacao_contas',
    label: 'Prestação de Contas',
    reference: 'prestacao_contas',
    url: 'sebrae-unidade/prestacao_contas',
    icon: 'finance/hand-coin-fill',
  },
  {
    id: 'infraestrutura',
    label: 'Infraestrutura de Atendimento',
    reference: 'infraestrutura',
    url: 'sebrae-unidade/infraestrutura',
    icon: 'user/parent-fill',
  },
  {
    id: 'inovacao',
    label: 'Inovação',
    reference: 'inovacao',
    url: 'sebrae-unidade/inovacao',
    icon: 'design/pencil-ruler-2-fill',
  },
  {
    id: 'integridade',
    label: 'Integridade',
    reference: 'integridade',
    url: 'sebrae-unidade/integridade',
    icon: 'map/earth-fill',
  },
  {
    id: 'perguntas',
    label: 'Perguntas e Respostas',
    reference: 'Perguntas_Respostas',
    url: 'https://sebrae.com.br/sites/PortalSebrae/buscacompleta',
    icon: 'communication/feedback-fill',
  },
  {
    id: 'lei',
    label: 'Lei de Acesso a Informação',
    reference: 'lei',
    url: 'sebrae-unidade/lei',
    icon: 'document-info',
  },
  {
    id: 'lgpd',
    label: 'Lei Geral de Proteção de Dados',
    reference: 'lgpd',
    url: 'https://sebrae.com.br/sites/PortalSebrae/LGPD',
    icon: 'document/file-lock-line',
  },
  {
    id: 'ouvidoria',
    label: 'Ouvidoria',
    reference: 'Ouvidoria',
    url: 'https://sebrae.com.br/sites/PortalSebrae/ouvidoriasebrae',
    icon: 'communication/chat-smile-3-line',
  },
  {
    id: 'esic',
    label: 'Serviço de Informação ao Cidadão (SIC)',
    reference: 'esic',
    url: 'sebrae-unidade/esic',
    icon: 'user/team-line',
  },
  {
    id: 'cursos',
    label: 'Cursos Gratuitos',
    reference: 'Cursos_Gratuitos',
    url: 'https://sebrae.com.br/sites/PortalSebrae/cursosonline',
    icon: 'user/star-smile-line',
  },
];

export const sideBarItensUnidade = [
  {
    id: 'institucional',
    label: 'Institucional',
    reference: 'institucional',
    url: 'sebrae-unidade/institucional',
    icon: 'buildings/building-2-fill',
  },
  {
    id: 'atos_normativos',
    label: 'Atos Normativos',
    reference: 'atos_normativos',
    url: 'sebrae-unidade/atos_normativos',
    icon: 'buildings/home-gear-fill',
  },
  {
    id: 'programas_projetos_resultados',
    label: 'Estratégia e Resultados',
    reference: 'programas_projetos_resultados',
    url: 'sebrae-unidade/programas_projetos_resultados',
    icon: 'buildings/home-gear-fill',
  },
  {
    id: 'orçamento_execucao_orcamentaria',
    label: 'Orçamento e Execução Orçamentária',
    reference: 'orçamento_execucao_orcamentaria',
    url: 'sebrae-unidade/orçamento_execucao_orcamentaria',
    icon: 'buildings/home-gear-fill',
  },
  {
    id: 'licitacoes',
    label: 'Licitações',
    reference: 'licitacoes',
    url: 'sebrae-unidade/licitacoes',
    icon: 'buildings/home-gear-fill',
  },
  {
    id: 'credenciamento',
    label: 'Credenciamento',
    reference: 'credenciamento',
    url: 'sebrae-unidade/credenciamento',
    icon: 'buildings/home-gear-fill',
  },
  {
    id: 'contratos_convenios',
    label: 'Contratos',
    reference: 'contratos_convenios',
    url: 'sebrae-unidade/contratos_convenios',
    icon: 'buildings/home-gear-fill',
  },
  {
    id: 'convenios_transferencia',
    label: 'Convênios e Transferências',
    reference: 'convenios_transferencia',
    url: 'sebrae-unidade/convenios_transferencia',
    icon: 'buildings/home-gear-fill',
  },
  {
    id: 'demonstracoes_contabeis',
    label: 'Demonstrações Contábeis',
    reference: 'demonstracoes_contabeis',
    url: 'sebrae-unidade/demonstracoes_contabeis',
    icon: 'buildings/home-gear-fill',
  },
  {
    id: 'dirigentes_empregados',
    label: 'Dirigentes e Empregados',
    reference: 'dirigentes_empregados',
    url: 'sebrae-unidade/dirigentes_empregados',
    icon: 'buildings/home-gear-fill',
  },
  {
    id: 'prestacao_contas',
    label: 'Prestação de Contas',
    reference: 'prestacao_contas',
    url: 'sebrae-unidade/prestacao_contas',
    icon: 'buildings/home-gear-fill',
  },
  {
    id: 'infraestrutura',
    label: 'Infraestrutura de Atendimento',
    reference: 'infraestrutura',
    url: 'sebrae-unidade/infraestrutura',
    icon: 'buildings/home-gear-fill',
  },
  {
    id: 'inovacao',
    label: 'Inovação',
    reference: 'inovacao',
    url: 'sebrae-unidade/inovacao',
    icon: 'buildings/home-gear-fill',
  },
  {
    id: 'integridade',
    label: 'Integridade',
    reference: 'integridade',
    url: 'sebrae-unidade/integridade',
    icon: 'buildings/home-gear-fill',
  },
  {
    id: 'perguntas',
    label: 'Perguntas e Respostas',
    reference: 'Perguntas_Respostas',
    url: 'https://sebrae.com.br/sites/PortalSebrae/buscacompleta',
    icon: 'communication/feedback-fill',
  },
  {
    id: 'lei',
    label: 'Lei de Acesso a Informação',
    reference: 'lei',
    url: 'sebrae-unidade/lei',
    icon: 'buildings/home-gear-fill',
  },
  {
    id: 'lgpd',
    label: 'Lei Geral de Proteção de Dados',
    reference: 'lgpd',
    url: 'https://sebrae.com.br/sites/PortalSebrae/LGPD',
    icon: 'document/file-lock-line',
  },
  {
    id: 'ouvidoria',
    label: 'Ouvidoria',
    reference: 'Ouvidoria',
    url: 'https://sebrae.com.br/sites/PortalSebrae/ouvidoriasebrae',
    icon: 'communication/chat-smile-3-line',
  },
  {
    id: 'esic',
    label: 'Serviço de Informação ao Cidadão (SIC)',
    reference: 'esic',
    url: 'sebrae-unidade/esic',
    icon: 'buildings/home-gear-fill',
  },
  {
    id: 'cursos',
    label: 'Cursos Gratuitos',
    reference: 'Cursos_Gratuitos',
    url: 'https://sebrae.com.br/sites/PortalSebrae/cursosonline',
    icon: 'user/star-smile-line',
  },
];

export const sideBarItensSistema = [
  {
    id: 'sistemassebrae_PrestacaoDeConta',
    label: 'Prestação de Contas',
    reference: 'sistemassebrae_PrestacaoDeConta',
    url: 'sistema-sebrae/sistemassebrae_PrestacaoDeConta',
    icon: 'finance/hand-coin-fill',
  },
  {
    id: 'sistemasebrae_DemonstracoesContabeis',
    label: 'Demonstrações Contábeis',
    reference: 'sistemasebrae_DemonstracoesContabeis',
    url: 'sistema-sebrae/sistemasebrae_DemonstracoesContabeis',
    icon: 'finance/coins-fill',
  },
  {
    id: 'sistemassebrae_Orcamentos',
    label: 'Orçamento e execução orçamentária',
    reference: 'sistemassebrae_Orcamentos',
    url: 'sistema-sebrae/sistemassebrae_Orcamentos',
    icon: 'business/calculator-fill',
  },
  {
    id: 'sistemassebrae_EstrategiaeResultado',
    label: 'Estratégia e Resultados',
    reference: 'sistemassebrae_EstrategiaeResultado',
    url: 'sistema-sebrae/sistemassebrae_EstrategiaeResultado',
    icon: 'business/bar-chart-box-fill',
  },
];
