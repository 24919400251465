import {
  BaSeBackendNoSignalPage,
  BaSeDialogContainer,
  BaSeErrorPage,
  BaSeForbiddenPage,
  BaSeLoadingPage,
  BaSeNotFoundPage,
  BaSeRoot,
} from '@base/react';
import * as Sebrae from '@sebrae/react-core';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import packageJson from '../package.json';
import reportWebVitals from './reportWebVitals';
import { Routes } from './routes';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.render(
  <React.StrictMode>
    <BaSeRoot>
      <Sebrae.Root
        appName={packageJson.name}
        appVersion={packageJson.version}
        errorPage={(error) => <BaSeErrorPage error={error} />}
        loadingPage={() => <BaSeLoadingPage />}
        notFoundPage={() => <BaSeNotFoundPage />}
        forbiddenPage={() => <BaSeForbiddenPage />}
        backendNoSignalPage={() => <BaSeBackendNoSignalPage />}
      >
        <Routes />
        <BaSeDialogContainer />
      </Sebrae.Root>
    </BaSeRoot>
  </React.StrictMode>,
  document.getElementById('root'),
);

serviceWorkerRegistration.register();

reportWebVitals();
