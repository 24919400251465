import { BaSeLoadingPage } from '@base/react';
import * as Sebrae from '@sebrae/react-core';
import React from 'react';
import { useParams } from 'react-router-dom';
import {
  sideBarItensSistema,
  sideBarItensUnidade,
} from './modules/main/utils/sideBarItems';

const HomePage = React.lazy(() => import('./modules/main/pages/home-page'));
const ProtectedPage = React.lazy(
  () => import('./modules/main/pages/protected-page'),
);

const InstitucionalPage = React.lazy(
  () =>
    import('./modules/main/pages/sistema-unidade/institucional/institucional'),
);
const NormativeActsPage = React.lazy(
  () =>
    import(
      './modules/main/pages/sistema-unidade/normative-acts/normative-acts'
    ),
);
const StrategyResultPage = React.lazy(
  () =>
    import(
      './modules/main/pages/sistema-unidade/strategy-result/strategy-result'
    ),
);
const AccreditationPage = React.lazy(
  () =>
    import('./modules/main/pages/sistema-unidade/Accreditation/Accreditation'),
);
const FinancialStatementsPage = React.lazy(
  () =>
    import(
      './modules/main/pages/sistema-unidade/financial-statements/financial-statements'
    ),
);
const FinancialStatementsSebraePage = React.lazy(
  () =>
    import(
      './modules/main/pages/sistema-sebrae/financial-statement/financial-statement'
    ),
);
const BuscaPage = React.lazy(
  () => import('./modules/main/pages/busca/search-page'),
);

const ContractsPage = React.lazy(
  () => import('./modules/main/pages/sistema-unidade/contracts/contracts'),
);
const AgreementsTransfersPage = React.lazy(
  () =>
    import(
      './modules/main/pages/sistema-unidade/agreements-transfers/agreements-transfers'
    ),
);
const ManagersEmployeersPage = React.lazy(
  () =>
    import(
      './modules/main/pages/sistema-unidade/managers-employees/managers-employees'
    ),
);
const AccountabilityPage = React.lazy(
  () =>
    import(
      './modules/main/pages/sistema-unidade/accountability/accountability'
    ),
);
const ServiceInfrastructurePage = React.lazy(
  () =>
    import(
      './modules/main/pages/sistema-unidade/service-infrastructure/service-infrastructure'
    ),
);
const IntegrityPage = React.lazy(
  () => import('./modules/main/pages/sistema-unidade/integrity/integrity'),
);
const LawPage = React.lazy(
  () => import('./modules/main/pages/sistema-unidade/law/law'),
);
const SicPage = React.lazy(
  () => import('./modules/main/pages/sistema-unidade/sic/sic'),
);
const BudgetExecutionPage = React.lazy(
  () =>
    import(
      './modules/main/pages/sistema-unidade/budget-execution/budget-execution'
    ),
);
const InnovationPage = React.lazy(
  () => import('./modules/main/pages/sistema-unidade/innovation/innovation'),
);
const BiddingPage = React.lazy(
  () => import('./modules/main/pages/sistema-unidade/bidding/bidding'),
);
const AccountabilitySystemPage = React.lazy(
  () =>
    import('./modules/main/pages/sistema-sebrae/accountability/accountability'),
);
const BudgetsSystemPage = React.lazy(
  () => import('./modules/main/pages/sistema-sebrae/budgets/accountability'),
);
const StrategyResultSystemPage = React.lazy(
  () =>
    import(
      './modules/main/pages/sistema-sebrae/strategy-results/strategy-results'
    ),
);

// eslint-disable-next-line sonarjs/cognitive-complexity
const DynamicPageLoader: React.FC = () => {
  const { tema } = useParams<{ tema: string; uf?: string }>();

  if (sideBarItensSistema.some((item) => item.url.includes(tema))) {
    switch (tema) {
      case 'sistemasebrae_DemonstracoesContabeis':
        return (
          <React.Suspense fallback={<BaSeLoadingPage />}>
            <FinancialStatementsSebraePage />
          </React.Suspense>
        );
      case 'sistemassebrae_Orcamentos':
        return (
          <React.Suspense fallback={<BaSeLoadingPage />}>
            <BudgetsSystemPage />
          </React.Suspense>
        );
      case 'sistemassebrae_PrestacaoDeConta':
        return (
          <React.Suspense fallback={<BaSeLoadingPage />}>
            <AccountabilitySystemPage />
          </React.Suspense>
        );
      case 'sistemassebrae_EstrategiaeResultado':
        return (
          <React.Suspense fallback={<BaSeLoadingPage />}>
            <StrategyResultSystemPage />
          </React.Suspense>
        );
      default:
        console.error('Tema inválido:', tema);
        return <BaSeLoadingPage />;
    }
  }

  if (sideBarItensUnidade.some((item) => item.reference === tema)) {
    switch (tema) {
      case 'institucional':
        return (
          <React.Suspense fallback={<BaSeLoadingPage />}>
            <InstitucionalPage />
          </React.Suspense>
        );
      case 'atos_normativos':
        return (
          <React.Suspense fallback={<BaSeLoadingPage />}>
            <NormativeActsPage />
          </React.Suspense>
        );
      case 'programas_projetos_resultados':
        return (
          <React.Suspense fallback={<BaSeLoadingPage />}>
            <StrategyResultPage />
          </React.Suspense>
        );
      case 'credenciamento':
        return (
          <React.Suspense fallback={<BaSeLoadingPage />}>
            <AccreditationPage />
          </React.Suspense>
        );
      case 'contratos_convenios':
        return (
          <React.Suspense fallback={<BaSeLoadingPage />}>
            <ContractsPage />
          </React.Suspense>
        );
      case 'convenios_transferencia':
        return (
          <React.Suspense fallback={<BaSeLoadingPage />}>
            <AgreementsTransfersPage />
          </React.Suspense>
        );
      case 'demonstracoes_contabeis':
        return (
          <React.Suspense fallback={<BaSeLoadingPage />}>
            <FinancialStatementsPage />
          </React.Suspense>
        );
      case 'dirigentes_empregados':
        return (
          <React.Suspense fallback={<BaSeLoadingPage />}>
            <ManagersEmployeersPage />
          </React.Suspense>
        );
      case 'prestacao_contas':
        return (
          <React.Suspense fallback={<BaSeLoadingPage />}>
            <AccountabilityPage />
          </React.Suspense>
        );
      case 'infraestrutura':
        return (
          <React.Suspense fallback={<BaSeLoadingPage />}>
            <ServiceInfrastructurePage />
          </React.Suspense>
        );
      case 'orçamento_execucao_orcamentaria':
        return (
          <React.Suspense fallback={<BaSeLoadingPage />}>
            <BudgetExecutionPage />
          </React.Suspense>
        );
      case 'licitacoes':
        return (
          <React.Suspense fallback={<BaSeLoadingPage />}>
            <BiddingPage />
          </React.Suspense>
        );
      case 'inovacao':
        return (
          <React.Suspense fallback={<BaSeLoadingPage />}>
            <InnovationPage />
          </React.Suspense>
        );
      case 'integridade':
        return (
          <React.Suspense fallback={<BaSeLoadingPage />}>
            <IntegrityPage />
          </React.Suspense>
        );
      case 'lei':
        return (
          <React.Suspense fallback={<BaSeLoadingPage />}>
            <LawPage />
          </React.Suspense>
        );
      case 'esic':
        return (
          <React.Suspense fallback={<BaSeLoadingPage />}>
            <SicPage />
          </React.Suspense>
        );
      default:
        return <BaSeLoadingPage />;
    }
  }

  return <BaSeLoadingPage />;
};

export const Routes = () => {
  const routesConfig = [
    {
      routePath: '/normativos',
      isExactPath: true,
      pageToRender: (
        <React.Suspense fallback={<BaSeLoadingPage />}>
          <HomePage />
        </React.Suspense>
      ),
    },
    {
      routePath: '/',
      isExactPath: true,
      pageToRender: (
        <React.Suspense fallback={<BaSeLoadingPage />}>
          <HomePage />
        </React.Suspense>
      ),
    },
    {
      routePath: '/sistema-sebrae/:tema',
      isExactPath: true,
      pageToRender: <DynamicPageLoader />,
    },
    {
      routePath: '/sebrae-unidade/:tema/:uf?',
      isExactPath: true,
      pageToRender: <DynamicPageLoader />,
    },
    {
      routePath: '/protegida',
      isExactPath: true,
      pageToRender: (
        <React.Suspense fallback={<BaSeLoadingPage />}>
          <ProtectedPage />
        </React.Suspense>
      ),
    },
    {
      routePath: '/busca',
      isExactPath: false,
      pageToRender: (
        <React.Suspense fallback={<BaSeLoadingPage />}>
          <BuscaPage />
        </React.Suspense>
      ),
    },
  ];

  return <Sebrae.Routes routes={routesConfig} />;
};
